import React from 'react';
import { Typography, Row, Col, Card, Button, Carousel } from 'antd';
import {
  QrcodeOutlined,
  SafetyOutlined,
  GlobalOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { motion } from 'framer-motion';

const { Title, Paragraph } = Typography;

const HomePage = ({ t }) => {
  return (
    <div style={{ padding: '24px' }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Carousel autoplay effect='fade'>
          <div>
            <h3 style={contentStyle}>{t.banner.title}</h3>
          </div>
          <div>
            <h3 style={contentStyle}>{t.banner.subtitle}</h3>
          </div>
        </Carousel>
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.8 }}
      >
        <Title level={2} style={{ textAlign: 'center', margin: '40px 0' }}>
          푸드QR: 식품 안전과 소비자 알 권리의 혁신
        </Title>
      </motion.div>

      <Row gutter={[16, 16]}>
        <Col xs={24} md={8}>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Card>
              <QrcodeOutlined style={{ fontSize: '36px', color: '#1890ff' }} />
              <Title level={4}>실시간 정보 제공</Title>
              <Paragraph>
                푸드QR을 통해 상세 제품 정보, 영양 정보, 알레르기 정보 등을 즉시
                확인할 수 있습니다.
              </Paragraph>
            </Card>
          </motion.div>
        </Col>
        <Col xs={24} md={8}>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Card>
              <SafetyOutlined style={{ fontSize: '36px', color: '#52c41a' }} />
              <Title level={4}>식품 안전성 향상</Title>
              <Paragraph>
                투명한 정보 공개로 식품 안전에 대한 소비자 신뢰도를 높입니다.
              </Paragraph>
            </Card>
          </motion.div>
        </Col>
        <Col xs={24} md={8}>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Card>
              <GlobalOutlined style={{ fontSize: '36px', color: '#722ed1' }} />
              <Title level={4}>글로벌 표준 적용</Title>
              <Paragraph>
                GS1 디지털링크 표준을 적용하여 국제적으로 호환 가능한 시스템을
                구축합니다.
              </Paragraph>
            </Card>
          </motion.div>
        </Col>
      </Row>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.8, duration: 0.8 }}
      >
        <Card style={{ marginTop: '40px', textAlign: 'center' }}>
          <Title level={3}>푸드QR 도입 배경</Title>
          <Paragraph>
            4차 산업 시대를 맞아 전 산업 분야에서 디지털 대전환이 추진되고
            있습니다. 특히, 코로나-19 팬데믹 이후 온라인 식품 구매가
            일상화되면서 디지털 환경에서의 식품 정보 제공의 중요성이 더욱
            커졌습니다.
          </Paragraph>
          <Button type='primary' icon={<QrcodeOutlined />} size='large'>
            푸드QR 자세히 알아보기
          </Button>
        </Card>
      </motion.div>

      <Row gutter={[16, 16]} style={{ marginTop: '40px' }}>
        <Col xs={24} md={12}>
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1, duration: 0.8 }}
          >
            <Card
              title='푸드QR의 기대 효과'
              extra={<a href='#'>더 보기</a>}
              style={{ height: '100%' }}
            >
              <ul>
                <li>소비자: 상세한 제품 정보 확인으로 알 권리 보장</li>
                <li>기업: 실시간 정보 업데이트 및 소비자와의 직접 소통</li>
                <li>
                  정부: 효과적인 식품 안전 관리 및 신속한 리콜 시스템 구축
                </li>
              </ul>
            </Card>
          </motion.div>
        </Col>
        <Col xs={24} md={12}>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1.2, duration: 0.8 }}
          >
            <Card
              title='푸드QR 도입 일정'
              extra={<ClockCircleOutlined />}
              style={{ height: '100%' }}
            >
              <ul>
                <li>2024년 11월: 국내 제조 가공식품 도입</li>
                <li>2025년: 수입식품으로 확대</li>
                <li>2026년: 농·임·축·수산물로 확대</li>
                <li>2027년 이후: 조리식품으로 확대</li>
              </ul>
            </Card>
          </motion.div>
        </Col>
      </Row>
    </div>
  );
};

const contentStyle = {
  height: '300px',
  color: '#fff',
  lineHeight: '300px',
  textAlign: 'center',
  background: '#364d79',
  fontSize: '24px',
};

export default HomePage;
