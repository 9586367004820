import React, { useState } from 'react';
import {
  Typography,
  Row,
  Col,
  Card,
  Slider,
  Switch,
  Radio,
  Tooltip,
  Image,
} from 'antd';
import {
  InfoCircleOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { motion } from 'framer-motion';

const { Title, Paragraph } = Typography;

const DisplayMethodPage = () => {
  const [qrSize, setQrSize] = useState(15);
  const [showLogo, setShowLogo] = useState(true);
  const [qrColor, setQrColor] = useState('black');

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 },
    },
  };

  return (
    <motion.div
      style={{ padding: '24px' }}
      variants={containerVariants}
      initial='hidden'
      animate='visible'
    >
      <motion.div variants={itemVariants}>
        <Title level={1}>푸드QR 표시 방법</Title>
      </motion.div>

      <motion.div variants={itemVariants}>
        <Paragraph>
          푸드QR을 효과적으로 표시하기 위해서는 크기, 위치, 인쇄 품질 등 여러
          요소를 고려해야 합니다. 이 가이드라인을 따라 소비자가 쉽게 스캔할 수
          있는 푸드QR을 제작하세요.
        </Paragraph>
      </motion.div>

      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <motion.div variants={itemVariants}>
            <Card title='QR 코드 크기 및 해상도' extra={<InfoCircleOutlined />}>
              <Paragraph>
                - 권장 크기: 15x15mm 이상 (콰이어트 존 제외) - 최소 크기:
                10x10mm (인식률 유지 필요) - 해상도: 300dpi 이상
              </Paragraph>
              <Slider
                min={10}
                max={30}
                value={qrSize}
                onChange={setQrSize}
                marks={{
                  10: '10mm',
                  15: '15mm (권장)',
                  30: '30mm',
                }}
              />
              <Paragraph>
                현재 선택된 크기: {qrSize}mm
                {qrSize < 15 && (
                  <Tooltip title='15mm 미만은 인식률이 저하될 수 있습니다.'>
                    <WarningOutlined
                      style={{ color: 'orange', marginLeft: '8px' }}
                    />
                  </Tooltip>
                )}
                {qrSize >= 15 && (
                  <Tooltip title='권장 크기입니다.'>
                    <CheckCircleOutlined
                      style={{ color: 'green', marginLeft: '8px' }}
                    />
                  </Tooltip>
                )}
              </Paragraph>
            </Card>
          </motion.div>
        </Col>
        <Col xs={24} md={12}>
          <motion.div variants={itemVariants}>
            <Card title='푸드QR 로고 및 색상' extra={<InfoCircleOutlined />}>
              <Paragraph>
                푸드QR 로고(EYE-Q) 삽입은 선택사항이지만, 대량인쇄형의 경우
                권장됩니다.
              </Paragraph>
              <Switch
                checked={showLogo}
                onChange={setShowLogo}
                checkedChildren='로고 표시'
                unCheckedChildren='로고 미표시'
              />
              <Paragraph style={{ marginTop: '16px' }}>
                QR 코드 색상 (바탕색은 항상 흰색)
              </Paragraph>
              <Radio.Group
                onChange={(e) => setQrColor(e.target.value)}
                value={qrColor}
              >
                <Radio.Button value='black'>검은색 (권장)</Radio.Button>
                <Radio.Button value='blue'>파란색</Radio.Button>
                <Radio.Button value='red'>빨간색</Radio.Button>
              </Radio.Group>
            </Card>
          </motion.div>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
        <Col xs={24} md={12}>
          <motion.div variants={itemVariants}>
            <Card title='표시 위치' extra={<InfoCircleOutlined />}>
              <Paragraph>
                - 주표시면 상단의 좌우가 구분되는 경우 우측에 표시 - 뚜껑,
                절취선, 접합부 등이 있는 경우 각 부분의 우측 상단에 표시 - 포장
                재질이나 용기 형태에 따라 잘 보이고 스캔하기 용이한 위치에 표시
              </Paragraph>
              <Image
                src='/api/placeholder/400/300'
                alt='푸드QR 표시 위치 예시'
                width={400}
              />
            </Card>
          </motion.div>
        </Col>
        <Col xs={24} md={12}>
          <motion.div variants={itemVariants}>
            <Card title='인쇄 방법' extra={<InfoCircleOutlined />}>
              <Paragraph>
                푸드QR 유형에 따라 적합한 인쇄 방식이 다릅니다:
              </Paragraph>
              <ul>
                <li>대량인쇄형: 일반 인쇄기로 대량 인쇄 가능</li>
                <li>안전소비형 & 이력추적형: 산업용 인쇄기(마킹기) 필요</li>
              </ul>
              <Paragraph>주요 산업용 인쇄 방식:</Paragraph>
              <ul>
                <li>연속식 잉크젯(CIJ)</li>
                <li>고해상도 잉크젯(TIJ)</li>
                <li>열전사(TTO)</li>
                <li>레이저 마킹</li>
              </ul>
            </Card>
          </motion.div>
        </Col>
      </Row>

      <motion.div variants={itemVariants} style={{ marginTop: '24px' }}>
        <Card title='주의사항' type='warning' extra={<WarningOutlined />}>
          <Paragraph>
            - 콰이어트 존(여백)을 반드시 확보 (상하좌우 1mm 이상) - QR 코드
            등급은 'C'등급 이상 유지 - 오류 복원 레벨은 'M' 레벨 권장 (약 15%
            오류 복원 능력) - 1차원 바코드와 병행 표시 시 적당한 거리 유지
          </Paragraph>
        </Card>
      </motion.div>
    </motion.div>
  );
};

export default DisplayMethodPage;
