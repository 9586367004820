import React, { useState } from 'react';

import {
  Layout,
  Menu,
  Button,
  Typography,
  Space,
  Card,
  Carousel,
  Popover,
  Modal,
  Tabs,
  Table,
  Timeline,
  Tooltip,
  Badge,
} from 'antd';
import {
  QrcodeOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  DownloadOutlined,
  GlobalOutlined,
  MenuOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import FAQComponent from './Components/Faq';
import IntroductionPage from './Components/IntroductionPage';
import HomePage from './Components/HomePage';
import TypesPage from './Components/TypesPage';
import DisplayMethodPage from './Components/DisplayMethodPage';

const { Header, Content, Footer } = Layout;
const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

const App = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [language, setLanguage] = useState('ko');
  // App 컴포넌트 내부
  const [currentPage, setCurrentPage] = useState('home');

  // 메뉴 항목 클릭 핸들러
  const handleMenuClick = (page) => {
    setCurrentPage(page);
  };
  const texts = {
    ko: {
      title: '푸드QR 공식 안내',
      menu: {
        home: '홈',
        about: '푸드QR 소개',
        types: '유형',
        howto: '표시 방법',
        faq: 'FAQ',
      },
      banner: {
        title: '푸드QR로 더 안전하고 스마트한 식품 정보',
        subtitle: '소비자의 알 권리와 식품 안전을 위한 혁신',
      },
      intro: {
        title: '푸드QR이란?',
        content:
          '푸드QR은 소비자가 휴대폰으로 식품 정보를 쉽게 확인할 수 있는 혁신적인 시스템입니다. QR 코드를 통해 상세한 제품 정보, 영양 정보, 알레르기 정보 등을 즉시 확인할 수 있습니다.',
      },
      types: {
        title: '푸드QR 유형',
        type1: '대량인쇄형',
        type2: '안전소비형',
        type3: '이력추적형',
      },
      howto: {
        title: '푸드QR 표시 방법',
        content:
          '푸드QR은 제품 포장의 주표시면 우측 상단에 표시하는 것이 권장됩니다. 최소 크기는 10x10mm이며, 스캔이 용이하도록 충분한 여백을 확보해야 합니다.',
      },
      timeline: {
        title: '푸드QR 도입 일정',
        step1: '2024년 11월: 국내 제조 가공식품 도입',
        step2: '2025년: 수입식품으로 확대',
        step3: '2026년: 농·임·축·수산물로 확대',
        step4: '2027년 이후: 조리식품으로 확대',
      },
    },
    en: {
      title: 'FoodQR Official Guide',
      menu: {
        home: 'Home',
        about: 'About FoodQR',
        types: 'Types',
        howto: 'How to Display',
        faq: 'FAQ',
      },
      banner: {
        title: 'Safer and Smarter Food Information with FoodQR',
        subtitle: 'Innovation for Consumer Rights and Food Safety',
      },
      intro: {
        title: 'What is FoodQR?',
        content:
          'FoodQR is an innovative system that allows consumers to easily check food information using their mobile phones. Through QR codes, detailed product information, nutritional information, and allergy information can be instantly verified.',
      },
      types: {
        title: 'FoodQR Types',
        type1: 'Mass Printing Type',
        type2: 'Safe Consumption Type',
        type3: 'Traceability Type',
      },
      howto: {
        title: 'How to Display FoodQR',
        content:
          'It is recommended to display FoodQR on the upper right side of the main display area of the product packaging. The minimum size is 10x10mm, and sufficient margin should be secured for easy scanning.',
      },
      timeline: {
        title: 'FoodQR Implementation Schedule',
        step1:
          'November 2024: Introduction for domestically manufactured processed foods',
        step2: '2025: Expansion to imported foods',
        step3:
          '2026: Expansion to agricultural, forestry, livestock, and fishery products',
        step4: '2027 and beyond: Expansion to prepared foods',
      },
    },
  };

  const t = texts[language];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const typesData = [
    {
      key: '1',
      type: t.types.type1,
      description: '대량 생산 제품을 위한 기본 QR 코드',
    },
    {
      key: '2',
      type: t.types.type2,
      description: '소비기한 정보가 포함된 QR 코드',
    },
    {
      key: '3',
      type: t.types.type3,
      description: '제품 이력 추적이 가능한 QR 코드',
    },
  ];

  const columns = [
    {
      title: '유형',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: '설명',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  return (
    <Layout className='layout'>
      <Header>
        <div className='logo' />
        <Menu
          theme='dark'
          mode='horizontal'
          selectedKeys={[currentPage]}
          onClick={({ key }) => setCurrentPage(key)}
        >
          <Menu.Item key='home'>{t.menu.home}</Menu.Item>
          <Menu.Item key='about'>{t.menu.about}</Menu.Item>
          <Menu.Item key='types'>{t.menu.types}</Menu.Item>
          <Menu.Item key='howto'>{t.menu.howto}</Menu.Item>
          <Menu.Item key='faq'>{t.menu.faq}</Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        {currentPage === 'home' && <HomePage t={t} />}
        {currentPage === 'faq' && <FAQComponent />}
        {currentPage === 'about' && <IntroductionPage />}
        {currentPage === 'types' && <TypesPage />}
        {currentPage === 'howto' && <DisplayMethodPage />}
      </Content>

      <Footer style={{ textAlign: 'center' }}>
        FoodQR ©2024 Created by 주식회사 야무진행동
      </Footer>

      <Modal
        title='푸드QR 상세 정보'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          푸드QR은 식품의 안전과 소비자의 알 권리를 보장하기 위해 도입된
          혁신적인 시스템입니다.
        </p>
        <p>
          QR 코드를 통해 제품의 상세 정보, 영양 정보, 알레르기 정보 등을 즉시
          확인할 수 있습니다.
        </p>
        <p>
          더 나아가 제품의 이력 추적, 리콜 정보 확인 등 다양한 기능을
          제공합니다.
        </p>
      </Modal>
    </Layout>
  );
};

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

export default App;
