import React, { useState } from 'react';
import {
  Typography,
  Row,
  Col,
  Card,
  Tabs,
  Button,
  Tooltip,
  Popover,
} from 'antd';
import {
  QrcodeOutlined,
  SafetyOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { motion } from 'framer-motion';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const TypesPage = () => {
  const [activeTab, setActiveTab] = useState('1');

  const typeData = [
    {
      key: '1',
      title: '대량인쇄형 (최소인쇄형)',
      icon: <QrcodeOutlined />,
      description:
        '모든 정보가 고정된 값으로 QR코드는 항상 같은 모양입니다. 포장지를 제작할 때 디자인 파일에 QR코드 이미지를 넣어 대량으로 인쇄할 수 있습니다.',
      format: 'https://foodqr.kr/01/08801234560016',
      usage:
        '소비자가 휴대폰 카메라로 푸드QR을 스캔하여 QR에 담긴 인터넷 주소(식약처 푸드QR 정보시스템)로 바로 접속해 다양한 정보를 확인할 수 있습니다.',
      example: '일반 가공식품, 장기 보존 식품',
    },
    {
      key: '2',
      title: '안전소비형',
      icon: <SafetyOutlined />,
      description:
        '제조 시점에 따라 제품의 소비기한 정보가 달라져 QR코드의 모양이 달라집니다. 제조 공정에서 QR코드를 인쇄 또는 부착해야 합니다.',
      format: 'https://foodqr.kr/01/08801234560016?7003=2405111230',
      usage:
        '유통·판매 단계에서 가격 계산(POS) 시 QR코드에 담긴 상품식별코드와 소비기한을 추출하여 등록된 위해식품을 차단하거나 소비기한 경과 식품을 자동으로 차단할 수 있습니다.',
      example: '유제품, 신선식품',
    },
    {
      key: '3',
      title: '이력추적형',
      icon: <HistoryOutlined />,
      description:
        '제조 시점에 따라 제품의 소비기한과 식품이력추적번호 정보가 달라져 QR코드의 모양이 달라집니다. 제조 공정에서 QR코드를 인쇄 또는 부착해야 합니다.',
      format:
        'https://foodqr.kr/01/08801234560016/21/8801234560016241231?17=241231',
      usage:
        '소비자가 포장지 푸드QR을 스캔할 경우 식품이력추적번호를 별도로 입력하지 않아도 해당 정보를 보여줄 수 있고 제조·유통 단계에서 식품이력추적 정보를 추출해 자동 등록·관리할 수도 있습니다.',
      example: '신선 농산물, 축산물',
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 },
    },
  };

  return (
    <div>
      <motion.div
        style={{ padding: '24px' }}
        variants={containerVariants}
        initial='hidden'
        animate='visible'
      >
        <motion.div variants={itemVariants}>
          <Title level={1}>푸드QR 유형</Title>
        </motion.div>
        <motion.div variants={itemVariants}>
          <Paragraph>
            푸드QR은 사용 목적과 적용 방식에 따라 세 가지 주요 유형으로
            구분됩니다. 각 유형은 특정 사용 사례와 장점을 가지고 있습니다.
          </Paragraph>
        </motion.div>
      </motion.div>

      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        tabPosition='left'
        style={{ marginTop: '2rem' }}
      >
        {typeData.map((type) => (
          <TabPane
            tab={
              <span>
                {type.icon} {type.title}
              </span>
            }
            key={type.key}
          >
            <motion.div
              variants={containerVariants}
              initial='hidden'
              animate='visible'
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <motion.div variants={itemVariants}>
                    <Card title={type.title} extra={<InfoCircleOutlined />}>
                      <Paragraph>{type.description}</Paragraph>
                    </Card>
                  </motion.div>
                </Col>
                <Col span={24}>
                  <motion.div variants={itemVariants}>
                    <Card title='형식 예시'>
                      <Paragraph copyable>{type.format}</Paragraph>
                    </Card>
                  </motion.div>
                </Col>
                <Col span={12}>
                  <motion.div variants={itemVariants}>
                    <Card title='주요 용도'>
                      <Paragraph>{type.usage}</Paragraph>
                    </Card>
                  </motion.div>
                </Col>
                <Col span={12}>
                  <motion.div variants={itemVariants}>
                    <Card title='적용 예시'>
                      <Paragraph>{type.example}</Paragraph>
                    </Card>
                  </motion.div>
                </Col>
              </Row>
            </motion.div>
          </TabPane>
        ))}
      </Tabs>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.5 }}
        style={{ marginTop: '2rem', textAlign: 'center' }}
      >
        <Popover
          content='푸드QR 유형에 대한 자세한 정보와 기술 사양을 확인할 수 있습니다.'
          title='기술 문서'
        >
          <Button type='primary' icon={<DownloadOutlined />} size='large'>
            푸드QR 기술 문서 다운로드
          </Button>
        </Popover>
      </motion.div>
    </div>
  );
};

export default TypesPage;
