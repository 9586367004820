import React, { useState } from 'react';
import {
  Typography,
  Collapse,
  Input,
  Tabs,
  Tag,
  List,
  Card,
  Space,
  Alert,
  Button,
} from 'antd';
import { SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';

const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;
const { TabPane } = Tabs;

const FAQComponent = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeKey, setActiveKey] = useState(['1']);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 },
    },
  };

  const faqData = [
    {
      category: '일반',
      questions: [
        {
          question: '푸드QR이란 무엇인가요?',
          answer: (
            <>
              푸드QR은 소비자가 휴대폰으로 식품 정보를 쉽게 확인할 수 있도록
              식품 포장지에 표시하는 QR코드입니다.
              <Text strong>
                상품식별코드와 해당 식품에 관한 다양한 정보로 연결되는 인터넷
                주소
              </Text>
              가 담겨 있습니다.
            </>
          ),
        },
        {
          question: '푸드QR은 언제부터 시행되나요?',
          answer: (
            <>
              푸드QR은 <Text strong>2024년 11월부터 국내 제조 가공식품</Text>을
              시작으로 단계적으로 도입될 예정입니다.
              <br />
              - 2025년: 수입식품
              <br />
              - 2026년: 농·임·축·수산물
              <br />- 2027년 이후: 조리식품으로 확대 적용
            </>
          ),
        },
        {
          question: '푸드QR의 도입 취지는 무엇인가요?',
          answer: (
            <>
              푸드QR의 주요 도입 취지는 다음과 같습니다:
              <br />
              1. <Text strong>식품표시 체계 개선</Text>
              <br />
              2. 실시간 식품 정보 전달
              <br />
              3. <Text strong>디지털 식품 정보 표준화</Text>
              <br />
              4. 기업 간 디지털 격차 해소
              <br />
              5. 식품 정보 디지털 활용 도모
            </>
          ),
        },
        {
          question: '푸드QR은 어떤 정보를 제공하나요?',
          answer: (
            <>
              푸드QR을 통해 다음과 같은 다양한 식품 관련 정보를 확인할 수
              있습니다:
              <br />- <Text strong>제품의 상세 정보</Text>
              <br />
              - 영양 정보
              <br />- <Text strong>알레르기 유발 원료</Text>
              <br />
              - 원산지 정보
              <br />
              - 제조일자, 유통기한, 소비기한
              <br />- <Text strong>리콜 정보</Text> 등
            </>
          ),
        },
        {
          question: '푸드QR은 어디에서 확인할 수 있나요?',
          answer: (
            <>
              푸드QR은 <Text strong>식품 포장의 주표시면 우측 상단</Text>에
              표시되는 것이 권장됩니다.
              <br />
              최소 크기는 10x10mm이며, 스캔이 용이하도록 충분한 여백을 확보해야
              합니다.
            </>
          ),
        },
        {
          question: '푸드QR 정보시스템은 언제부터 가동되나요?',
          answer: (
            <>
              식약처에서 제공할 푸드QR 정보시스템은{' '}
              <Text strong>2024년 11월에 최초 가동</Text>될 예정입니다.
            </>
          ),
        },
        {
          question: '푸드QR은 모든 식품에 의무적으로 적용되나요?',
          answer: (
            <>
              현재 푸드QR은 <Text strong>의무사항이 아닙니다</Text>. 향후
              제도적인 사항으로 소비자, 식품업계 등 이해관계자와 충분한 논의를
              거쳐 추진될 예정입니다.
            </>
          ),
        },
        {
          question: '푸드QR과 기존 바코드의 차이점은 무엇인가요?',
          answer: (
            <>
              푸드QR은 기존 바코드보다{' '}
              <Text strong>더 많은 정보를 담을 수 있으며</Text>, 스마트폰으로
              쉽게 스캔하여 온라인 정보에 접근할 수 있습니다. 또한{' '}
              <Text strong>실시간 정보 업데이트가 가능</Text>합니다.
            </>
          ),
        },
        {
          question: '푸드QR 도입으로 어떤 이점이 있나요?',
          answer: (
            <>
              1. 소비자:{' '}
              <Text strong>더 많은 식품 정보를 쉽게 얻을 수 있음</Text>
              <br />
              2. 기업: 실시간 정보 제공 및 마케팅에 활용 가능
              <br />
              3. 정부: <Text strong>
                식품 안전 관리를 더욱 효과적으로 수행
              </Text>{' '}
              가능
            </>
          ),
        },
        {
          question: '푸드QR 관련 정보는 어디서 더 얻을 수 있나요?',
          answer: (
            <>
              <Text strong>식품의약품안전처 홈페이지</Text>나
              <Text strong>푸드QR 전용 포털(www.foodqr.kr)</Text>에서 더 자세한
              정보를 얻을 수 있습니다.
            </>
          ),
        },
      ],
    },
    {
      category: '기술',
      questions: [
        {
          question: '푸드QR을 어떻게 스캔하나요?',
          answer: (
            <>
              대부분의 스마트폰 카메라 앱에는 QR 코드 스캔 기능이 내장되어
              있습니다.
              <Text strong>
                카메라 앱을 열고 푸드QR에 초점을 맞추면 자동으로 인식
              </Text>
              됩니다. 별도의 QR 코드 스캐너 앱을 사용할 수도 있습니다.
            </>
          ),
        },
        {
          question: '푸드QR의 기술적 표준은 무엇인가요?',
          answer: (
            <>
              푸드QR은 <Text strong>국제 표준인 GS1 Digital Link</Text>를
              기반으로 합니다. 이는 기존의 바코드 시스템을 웹 기반의 정보
              시스템과 연결하는 혁신적인 기술입니다.
            </>
          ),
        },
        {
          question: '푸드QR의 크기는 어떻게 되나요?',
          answer: (
            <>
              푸드QR의{' '}
              <Text strong>권장 인쇄 크기는 15x15mm 이상, 300dpi 이상</Text>
              입니다.
              <br />
              면적 제한 시 최소 10x10mm 이상으로 할 수 있지만, 인식률 유지를
              위해 주의가 필요합니다.
            </>
          ),
        },
        {
          question: 'QR 코드의 오류 복원 레벨은 무엇을 사용하나요?',
          answer: (
            <>
              QR 코드의 오류 복원 레벨은 <Text strong>'M' 레벨을 권장</Text>
              합니다. 이는 약 15%의 오류 복원 능력을 제공합니다.
            </>
          ),
        },
        {
          question: '푸드QR의 색상은 어떻게 해야 하나요?',
          answer: (
            <>
              <Text strong>바탕색은 흰색, QR 패턴은 검은색을 권장</Text>합니다.
              다른 색상을 선택할 경우 서로 대비되는 색상을 사용하여 인식률이
              저하되지 않도록 해야 합니다.
            </>
          ),
        },
        {
          question: '푸드QR에는 어떤 정보가 포함되나요?',
          answer: (
            <>
              푸드QR에는 <Text strong>상품식별코드(GTIN)가 항상 포함</Text>되며,
              선택적으로 소비기한, 생산일자, 로트번호 등의 정보를 추가할 수
              있습니다.
            </>
          ),
        },
        {
          question: '푸드QR은 어떤 형식으로 구성되나요?',
          answer: (
            <>
              푸드QR은 GS1 디지털링크 표준을 따르며,
              <Text strong>"https://foodqr.kr/01/상품식별코드" 형식의 URL</Text>
              로 구성됩니다. 추가 정보는 URL의 쿼리 파라미터로 포함될 수
              있습니다.
            </>
          ),
        },
        {
          question: '푸드QR 생성 시 주의할 점은 무엇인가요?',
          answer: (
            <>
              푸드QR 생성 시 <Text strong>콰이어트 존(여백)을 반드시 확보</Text>
              해야 하며, QR 코드의 등급은 'C'등급 이상이어야 합니다.
            </>
          ),
        },
        {
          question: '푸드QR과 기존 바코드를 함께 사용할 수 있나요?',
          answer: (
            <>
              네, 가능합니다. 푸드QR과 1차원 바코드를 병행 표시할 경우,
              <Text strong>적당한 거리의 여백 공간을 두고 표시</Text>하거나 서로
              다른 사분면에 표시하는 것을 권장합니다.
            </>
          ),
        },
        {
          question: '푸드QR 정보는 어떻게 업데이트되나요?',
          answer: (
            <>
              푸드QR에 연결된 온라인 정보는{' '}
              <Text strong>실시간으로 업데이트</Text>될 수 있습니다. 제조업체는
              푸드QR 정보시스템을 통해 제품 정보를 관리하고 업데이트할 수
              있습니다.
            </>
          ),
        },
      ],
    },
    {
      category: '적용',
      questions: [
        {
          question: '어떤 제품에 푸드QR이 적용되나요?',
          answer: (
            <>
              푸드QR은 단계적으로 적용됩니다:
              <br />
              1. <Text strong>초기: 국내 제조 가공식품</Text>
              <br />
              2. 수입식품
              <br />
              3. 농·임·축·수산물
              <br />
              4. 조리식품
            </>
          ),
        },
        {
          question: '소규모 식품 제조업체도 푸드QR을 적용해야 하나요?',
          answer: (
            <>
              푸드QR 적용은 단계적으로 이루어질 예정이며,
              <Text strong>소규모 업체에 대한 지원 방안도 마련될 것</Text>
              입니다. 정확한 적용 범위와 시기는 관련 법규가 확정되면 공지될
              예정입니다.
            </>
          ),
        },
        {
          question: '푸드QR 발급은 어떻게 받나요?',
          answer: (
            <>
              <Text strong>
                2024년 11월부터 식약처 푸드QR 포털 누리집(www.foodqr.kr)에 접속
              </Text>
              하여 유통 제품별(상품식별코드별)로 제품 정보를 등록하고 푸드QR을
              발급받을 수 있습니다.
            </>
          ),
        },
        {
          question: '푸드QR 표시 위치는 어디인가요?',
          answer: (
            <>
              푸드QR은{' '}
              <Text strong>
                주표시면 상단의 좌우가 구분되는 경우 우측에 표시
              </Text>
              하는 것을 권장합니다. 뚜껑, 절취선, 접합부 등이 있는 경우 각
              부분의 우측 상단에 표시하는 것을 권장합니다.
            </>
          ),
        },
        {
          question: '푸드QR 적용 시 기존 표시사항을 모두 제거해도 되나요?',
          answer: (
            <>
              아닙니다.{' '}
              <Text strong>현재로서는 기존 표시사항을 그대로 유지</Text>해야
              합니다. 푸드QR은 추가 정보 제공을 위한 수단으로 사용됩니다.
            </>
          ),
        },
        {
          question: '푸드QR 적용 시 필요한 장비는 무엇인가요?',
          answer: (
            <>
              푸드QR 유형에 따라 다르지만, 일반적으로{' '}
              <Text strong>산업용 인쇄기(마킹기), QR코드 검증기</Text> 등이
              필요할 수 있습니다. 자세한 사항은 식약처나 관련 업체에 문의하시기
              바랍니다.
            </>
          ),
        },
        {
          question: '푸드QR 정보 등록은 어떻게 하나요?',
          answer: (
            <>
              푸드QR 정보시스템이 가동되면{' '}
              <Text strong>해당 시스템을 통해 제품 정보를 등록하고 관리</Text>할
              수 있습니다. 구체적인 방법은 시스템 오픈 시 안내될 예정입니다.
            </>
          ),
        },
        {
          question: '푸드QR 적용 시 비용은 얼마나 드나요?',
          answer: (
            <>
              비용은 기업의 규모, 제품 종류, 적용 방식 등에 따라 다를 수
              있습니다.
              <Text strong>정부에서는 중소기업을 위한 지원 방안을 검토 중</Text>
              입니다.
            </>
          ),
        },
        {
          question: '해외 수출 제품에도 푸드QR을 적용해야 하나요?',
          answer: (
            <>
              현재로서는 <Text strong>국내 유통 제품을 대상</Text>으로 하고
              있습니다. 해외 수출 제품에 대한 적용 여부는 추후 결정될
              예정입니다.
            </>
          ),
        },
        {
          question: '푸드QR 적용 시 주의해야 할 점은 무엇인가요?',
          answer: (
            <>
              푸드QR 적용 시 주의해야 할 주요 사항:
              <br />
              1. <Text strong>QR 코드의 인쇄 품질 유지</Text>
              <br />
              2. 충분한 여백 확보
              <br />
              3. 적절한 위치 선정
              <br />
              4. <Text strong>정확한 정보 등록</Text>
              <br />
              5. 주기적인 정보 업데이트
              <br />
              6. 소비자 편의성 고려
            </>
          ),
        },
      ],
    },
  ];

  const filteredFAQ = faqData
    .filter(
      (category) =>
        selectedCategory === 'all' || category.category === selectedCategory
    )
    .map((category) => ({
      ...category,
      questions: category.questions.filter(
        (q) =>
          q.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
          q.answer.props.children.some(
            (child) =>
              typeof child === 'string' &&
              child.toLowerCase().includes(searchTerm.toLowerCase())
          )
      ),
    }))
    .filter((category) => category.questions.length > 0);
  const recommendedFAQ = faqData.flatMap((category) =>
    category.questions.slice(0, 3)
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <motion.div
          style={{ padding: '24px' }}
          variants={containerVariants}
          initial='hidden'
          animate='visible'
        >
          <motion.div variants={itemVariants}>
            <Title level={1}>자주 묻는 질문 (FAQ)</Title>
          </motion.div>
        </motion.div>

        <motion.div variants={itemVariants}>
          <Alert
            message='푸드QR에 대해 궁금한 점이 있으신가요?'
            description='아래에서 자주 묻는 질문들을 확인하실 수 있습니다. 원하는 답변을 찾지 못하셨다면 문의하기 버튼을 눌러주세요.'
            type='info'
            showIcon
            action={
              <Button size='small' type='primary'>
                문의하기
              </Button>
            }
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Input
            prefix={<SearchOutlined />}
            placeholder='질문 검색하기'
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: 16 }}
          />
          <Space wrap>
            <Button
              type={selectedCategory === 'all' ? 'primary' : 'default'}
              onClick={() => setSelectedCategory('all')}
            >
              전체
            </Button>
            {faqData.map((category) => (
              <Button
                key={category.category}
                type={
                  selectedCategory === category.category ? 'primary' : 'default'
                }
                onClick={() => {
                  setSelectedCategory(category.category);
                  setActiveKey([]); // 모든 Collapse 패널을 닫습니다.
                }}
              >
                {category.category}
              </Button>
            ))}
          </Space>
        </motion.div>

        <Tabs
          activeKey={selectedCategory === 'all' ? '1' : selectedCategory}
          onChange={(key) => setSelectedCategory(key === '1' ? 'all' : key)}
        >
          <AnimatePresence>
            {filteredFAQ.map((category, index) => (
              <TabPane tab={category.category} key={index + 1}>
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                >
                  <Collapse
                    accordion
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                  >
                    {category.questions.map((item, qIndex) => (
                      <Panel
                        header={
                          <Space>
                            <QuestionCircleOutlined />
                            <span>{item.question}</span>
                          </Space>
                        }
                        key={`${index}-${qIndex}`}
                      >
                        <Paragraph>{item.answer}</Paragraph>
                      </Panel>
                    ))}
                  </Collapse>
                </motion.div>
              </TabPane>
            ))}
          </AnimatePresence>
        </Tabs>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <Card title='추천 FAQ'>
            <List
              itemLayout='horizontal'
              dataSource={recommendedFAQ}
              renderItem={(item, index) => (
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.1 * index, duration: 0.5 }}
                >
                  <List.Item>
                    <List.Item.Meta
                      avatar={<QuestionCircleOutlined />}
                      title={item.question}
                      description={item.answer}
                    />
                  </List.Item>
                </motion.div>
              )}
            />
          </Card>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          <Tag color='blue'>
            더 많은 정보는 푸드QR 공식 홈페이지를 참조하세요
          </Tag>
        </motion.div>
      </Space>
    </motion.div>
  );
};

export default FAQComponent;
