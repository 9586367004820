import React from 'react';
import {
  Typography,
  Row,
  Col,
  Card,
  Timeline,
  Statistic,
  Carousel,
  Image,
  Divider,
} from 'antd';
import {
  QrcodeOutlined,
  SafetyOutlined,
  GlobalOutlined,
  MobileOutlined,
} from '@ant-design/icons';
import { motion } from 'framer-motion';

const { Title, Paragraph } = Typography;

const IntroductionPage = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: 'spring', stiffness: 100 },
    },
  };

  return (
    <motion.div
      style={{ padding: '24px' }}
      variants={containerVariants}
      initial='hidden'
      animate='visible'
    >
      <motion.div variants={itemVariants}>
        <Title level={1}>푸드QR 소개</Title>
      </motion.div>

      <motion.div variants={itemVariants}>
        <Carousel autoplay effect='fade'>
          <div>
            <Image
              src='/api/placeholder/1200/400'
              alt='푸드QR 배너 1'
              style={{ width: '100%', height: '400px', objectFit: 'cover' }}
            />
            <Title level={2} style={overlayStyle}>
              안전한 식품 정보의 시작, 푸드QR
            </Title>
          </div>
          <div>
            <Image
              src='/api/placeholder/1200/400'
              alt='푸드QR 배너 2'
              style={{ width: '100%', height: '400px', objectFit: 'cover' }}
            />
            <Title level={2} style={overlayStyle}>
              스마트폰으로 쉽게 확인하는 식품 정보
            </Title>
          </div>
        </Carousel>
      </motion.div>

      <Divider />

      <Row gutter={[16, 16]}>
        {[
          { title: '도입', value: '2024년 11월', icon: <QrcodeOutlined /> },
          {
            title: '적용 대상 식품',
            value: '모든 식품',
            icon: <SafetyOutlined />,
          },
          { title: '글로벌 표준', value: 'GS1', icon: <GlobalOutlined /> },
          {
            title: '스캔 방식',
            value: '스마트폰 카메라',
            icon: <MobileOutlined />,
          },
        ].map((item, index) => (
          <Col xs={24} sm={12} md={6} key={index}>
            <motion.div variants={itemVariants}>
              <Card>
                <Statistic
                  title={item.title}
                  value={item.value}
                  prefix={item.icon}
                />
              </Card>
            </motion.div>
          </Col>
        ))}
      </Row>

      <Divider />

      <motion.div variants={itemVariants}>
        <Title level={2}>푸드QR이란?</Title>
        <Paragraph>
          푸드QR은 소비자가 휴대폰으로 식품 정보를 쉽게 확인할 수 있도록 하는
          혁신적인 시스템입니다. 식품 포장에 표시된 QR 코드를 스캔하면 해당
          제품의 상세 정보, 영양 정보, 알레르기 정보 등을 즉시 확인할 수
          있습니다.
        </Paragraph>
      </motion.div>

      <Row gutter={[16, 16]} align='middle'>
        <Col xs={24} md={12}>
          <motion.div variants={itemVariants}>
            <Image
              src='/api/placeholder/600/400'
              alt='푸드QR 예시'
              style={{ width: '100%', maxWidth: '600px' }}
            />
          </motion.div>
        </Col>
        <Col xs={24} md={12}>
          <motion.div variants={itemVariants}>
            <Title level={3}>푸드QR의 주요 특징</Title>
            <ul>
              <li>실시간 정보 업데이트 가능</li>
              <li>글로벌 표준(GS1 Digital Link) 적용</li>
              <li>스마트폰으로 간편하게 스캔</li>
              <li>다양한 추가 정보 제공 가능</li>
            </ul>
          </motion.div>
        </Col>
      </Row>

      <Divider />

      <motion.div variants={itemVariants}>
        <Title level={2}>푸드QR 도입 일정</Title>
        <Timeline mode='alternate'>
          <Timeline.Item>2024년 11월: 국내 제조 가공식품 도입</Timeline.Item>
          <Timeline.Item>2025년: 수입식품으로 확대</Timeline.Item>
          <Timeline.Item>2026년: 농·임·축·수산물로 확대</Timeline.Item>
          <Timeline.Item>2027년 이후: 조리식품으로 확대</Timeline.Item>
        </Timeline>
      </motion.div>

      <Divider />

      <motion.div variants={itemVariants}>
        <Title level={2}>푸드QR의 이점</Title>
        <Row gutter={[16, 16]}>
          {[
            {
              title: '소비자',
              icon: <SafetyOutlined />,
              benefits: [
                '상세한 제품 정보 확인',
                '알레르기 정보 즉시 확인',
                '리콜 정보 실시간 확인',
              ],
            },
            {
              title: '기업',
              icon: <GlobalOutlined />,
              benefits: [
                '실시간 정보 업데이트',
                '소비자와의 직접 소통',
                '마케팅 도구로 활용',
              ],
            },
            {
              title: '정부',
              icon: <QrcodeOutlined />,
              benefits: [
                '효과적인 식품 안전 관리',
                '신속한 리콜 시스템',
                '식품 정보의 디지털화',
              ],
            },
          ].map((item, index) => (
            <Col xs={24} sm={12} md={8} key={index}>
              <motion.div variants={itemVariants}>
                <Card title={item.title} extra={item.icon}>
                  <ul>
                    {item.benefits.map((benefit, idx) => (
                      <li key={idx}>{benefit}</li>
                    ))}
                  </ul>
                </Card>
              </motion.div>
            </Col>
          ))}
        </Row>
      </motion.div>
    </motion.div>
  );
};

const overlayStyle = {
  position: 'absolute',
  bottom: '20px',
  left: '20px',
  color: 'white',
  backgroundColor: 'rgba(0,0,0,0.5)',
  padding: '10px',
  borderRadius: '5px',
};

export default IntroductionPage;
